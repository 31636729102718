import React, {useState} from 'react'
import {useDrag, DragSourceMonitor} from 'react-dnd'
import {Status, Task, User} from "../../dataTypes";
import {EntryContainer} from "./EntryContainer";
import './TaskContainer.css'
import {RootState} from "../../redux/store";
import {getActiveTasks, getFinishedTasks, getOpenTasks, getTasks, getUsers} from "../../redux/data/data.reducer";
import {Dispatch} from "redux";
import {activateTask, deactivateTask, setStatus} from "../../redux/data/data.actions";
import {connect, ConnectedProps} from "react-redux";
import {getCurrentUser} from "../../redux/authentication/auth.reducer";
import {Button} from 'reactstrap';


const ItemTypes = {
    TASK: 'task',
}

interface TaskProps {
    task: Task,
    users: User[]
}

const TaskContainer: React.FC<PropsFromRedux & TaskProps> = (props) => {
    const [{isDragging}, drag, preview] = useDrag({
        item: {id: props.task.id, title: props.task.title, status: props.task.status, type: ItemTypes.TASK},
        end: (item: { status: Status, id: number, title: string } | undefined, monitor: DragSourceMonitor) => {
            const dropResult: { status: Status } = monitor.getDropResult()
            if (item && dropResult) {
                if (dropResult.status === Status.active && props.task.status !== Status.active) {// activate
                    const user = props.getCurrentUser;
                    if (user) {
                        props.onActivateTask(props.task.id, user.id)
                    }
                } else if (dropResult.status !== Status.active && props.task.status === Status.active) { //deactivate
                    props.onDeactivateTask(props.task.id)
                }
                props.onSetStatus(props.task.itemId, props.task.id, dropResult.status)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const [detailed, setDetailed] = useState(false);
    const opacity = isDragging ? 0.4 : 1

    function getUserById(id: number) {
        for (let user of props.users) {
            if (user.id === id) return user;
        }
    }

    function getSummedWorktime() {
        const timeWorkedMs = props.task.entries.map(e => e.end ? e.end - e.start : Date.now() - e.start).reduce(((p, c) => p + c), 0);
        return (Math.round(timeWorkedMs / 60 / 60 / 100) / 10)
    }

    return (
        <>
            <div style={{opacity}} className={'card'} ref={preview}>
                <div id={'taskTitleContainer'} style={{cursor: 'move'}} ref={drag} className={'card-header'}>
                    <div>{detailed.valueOf() ?
                        <Button onClick={() => setDetailed(false)}>
                            <i className="fas fa-angle-up"/>
                        </Button>
                        :
                        <Button onClick={() => setDetailed(true)}>
                            <i className="fas fa-angle-down"/>
                        </Button>}
                    </div>
                    <div style={{flexGrow: 2}}>
                        {props.task.title}
                    </div>
                    <div>
                        {getSummedWorktime()}/{props.task.estimatedDuration}
                    </div>
                </div>
                {detailed.valueOf() ? <div className={'card-body'}>
                        {props.task.entries.map((entry) => {
                            const user = getUserById(entry.userID)
                            if (user === undefined) {
                                console.log('UserID wasn\'t found!');
                                return <></>
                            }
                            return (
                                <EntryContainer key={entry.id} entry={entry} user={user}>
                                </EntryContainer>
                            )
                        })}
                    </div> :
                    <></>
                }

            </div>
        </>
    )
}

const mapState = (state: RootState) => {
    return {
        openTasks: getOpenTasks(state.data),
        activeTasks: getActiveTasks(state.data),
        finishedTasks: getFinishedTasks(state.data),
        tasks: getTasks(state.data),
        users: getUsers(state.data),
        getCurrentUser: getCurrentUser(state.auth),
    }
}

const mapDispatch = (dispatch: Dispatch) => {
    return {
        onSetStatus: (itemId: number, taskId: number, status: Status) => dispatch(setStatus(itemId, taskId, status)),
        onActivateTask: (taskId: number, userID: number) => dispatch(activateTask(taskId, userID)),
        onDeactivateTask: (taskId: number) => dispatch(deactivateTask(taskId)),
    }
}

const connector = connect(
    mapState,
    mapDispatch
)

type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(TaskContainer)