export enum Status {
    open = 'OPEN',
    active = 'ACTIVE',
    finished = 'FINISHED',
}

export type Item = {
    id: number,
    title: string,
    tasks: Task[],
}

export type Task = {
    id: number,
    itemId: number,
    status: Status,
    title: string,
    estimatedDuration: number,
    entries: Entry[],

}

export type Entry = {
    userID: number,
    start: number,
    end: number | undefined,
    id: number
}

export type User = {
    id: number,
    username: string,
    firstName: string,
    lastName: string,
    password: string,
}

export function getExampleItems(): Item[] {
    const item1 = {
        id: 0,
        title: "UI-Prototypen anfertigen",
        tasks: [
            {
                id: 0,
                itemId: 0,
                status: Status.active,
                title: "Taskboard erstellen",
                estimatedDuration: 10,
                entries: [
                    {
                        id: 0,
                        userID: 3,
                        start: Date.now() - 2500000,
                        end: Date.now() - 1300000
                    },
                    {
                        id: 1,
                        userID: 2,
                        start: Date.now() - 1200000,
                        end: undefined
                    },
                ]
            },
            {
                id: 1,
                itemId: 0,
                status: Status.finished,
                title: "Backlog-Item Erstellung implementieren",
                estimatedDuration: 8,
                entries: []
            },
            {
                id: 2,
                itemId: 0,
                status: Status.open,
                title: "Task-Erstellung implementieren",
                estimatedDuration: 6,
                entries: []
            }
        ]
    }
    const item2 = {
        id: 1,
        title: "Datenbank anlegen",
        tasks: [
            {
                id: 3,
                itemId: 1,
                status: Status.active,
                title: "Schemas erstellen",
                estimatedDuration: 4.5,
                entries: [

                    {
                        id: 2,
                        userID: 4,
                        start: Date.now() - 1200000,
                        end: undefined
                    },
                ]
            },
            {
                id: 4,
                itemId: 1,
                status: Status.finished,
                title: "MySQL -Benutzerstruktur anlegen",
                estimatedDuration: 7,
                entries: [{
                    id: 3,
                    userID: 3,
                    start: Date.now() - 2500000,
                    end: Date.now() - 1300000
                }, {
                    id: 4,
                    userID: 4,
                    start: Date.now() - 2500000,
                    end: Date.now() - 1300000
                },]
            },
            {
                id: 5,
                itemId: 1,
                status: Status.open,
                title: "Daten aus alter Datenbank exportieren",
                estimatedDuration: 6.8,
                entries: []
            }
        ]
    }
    const item3 = {
        id: 2,
        title: "Backend API",
        tasks: [
            {
                id: 6,
                itemId: 2,
                status: Status.active,
                title: "Erstellung der Endpunkte",
                estimatedDuration: 8,
                entries: [
                    {
                        id: 5,
                        userID: 3,
                        start: Date.now() - 1200000,
                        end: undefined
                    },
                ]
            },
            {
                id: 7,
                itemId: 2,
                status: Status.open,
                title: "Login Funktion via JWT-Token implementieren",
                estimatedDuration: 3,
                entries: [{
                    id: 6,
                    userID: 3,
                    start: Date.now() - 2500000,
                    end: Date.now() - 1300000
                }, {
                    id: 7,
                    userID: 2,
                    start: Date.now() - 2500000,
                    end: Date.now() - 1300000
                },]
            },
            {
                id: 8,
                itemId: 2,
                status: Status.open,
                title: "Tests schreiben",
                estimatedDuration: 10,
                entries: []
            }
        ]
    }
    return [item1, item2, item3
    ]

}

export function getExampleUsers(): User[] {
    return [
        {
            id: 0,
            username: 'user',
            password: 'user',
            firstName: 'user',
            lastName: 'user',
        },
        {
            id: 1,
            username: 'Max.Mustermann',
            password: 'password',
            firstName: 'Max',
            lastName: 'Mustermann',
        },
        {
            id: 2,
            username: 'Thomas.Grininger',
            password: 'password',
            firstName: 'Thomas',
            lastName: 'Grininger',
        },
        {
            id: 3,
            username: 'Christopher.Gusenbauer',
            password: 'password',
            firstName: 'Christopher',
            lastName: 'Gusenbauer',
        },
        {
            id: 4,
            username: 'Dominik.Fischer',
            password: 'password',
            firstName: 'Dominik',
            lastName: 'Fischer',
        },
    ]
}





