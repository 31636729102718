import {LOGIN, LOGOUT} from './auth.types';

export function logout() {
    return {
        type: LOGOUT
    }
}

export function login(credentials: { user: string, password: string }) {

    return {
        type: LOGIN,
        credentials
    }

}

