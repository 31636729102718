import {Card, CardHeader, CardBody, Button, Form, FormGroup, Label, Input, Alert} from "reactstrap";

import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {RootState} from "../../redux/store";
import React from "react";
import {
    getItems,
    getListItemTitles, getListTaskTitles, getTasks,
} from "../../redux/data/data.reducer";
import {editTaskDuration, editTaskTitle} from "../../redux/data/data.actions";
import {Task} from "../../dataTypes";

class EditTask extends React.Component<PropsFromRedux & EditTaskProps, EditTaskState> {

    constructor(props: PropsFromRedux & EditTaskProps) {
        super(props);

        this.state = {
            title: '',
            duration: 0,
            error: null,
            taskID: this.props.tasks[0] ? this.props.tasks[0].id : -1,
            itemID: this.props.items[0] ? this.props.items[0].id : -1
        }
    }

    render() {
        return this.props.tasks.length < 1 ? <></> : (
            <>
                <Card className={this.props.className}>
                    <CardHeader>
                        Edit Task
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="taskSelect">Select a Task</Label>
                                <Input type="select" name="select" id="taskSelect"
                                       onChange={event => this.setTaskIdItemIdFromTitle(event.target.value)}>
                                    {this.props.tasks.map((task: Task) => {
                                        return (
                                            <option key={task.id}>
                                                {task.title}
                                            </option>
                                        )
                                    })}

                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="taskTitle">Title</Label>
                                <Input type="text" name="title" id="taskTitle"
                                       placeholder="Task Title"
                                       onChange={(event => this.setTitle(event.target.value))}/>
                            </FormGroup>
                            <FormGroup>
                                <Label className={"pb-1"} for="">Estimated Duration in Hours</Label>
                                <Input type="number" name="duration" id="duration"
                                       placeholder={"20"}
                                       onChange={event => this.setDuration(event.target.valueAsNumber)}/>
                            </FormGroup>
                            {this.state.error ? <Alert style={{textAlign: "center"}} className={"my-2"}
                                                       color={"danger"}>{this.state.error}</Alert> : <></>}
                            <Button name="addItem" id="addItem" onClick={() => this.submitForm()}>Save Edit</Button>
                        </Form>
                    </CardBody>
                </Card>
            </>
        )
    }


    setError(newError: string | null) {
        this.setState({error: newError})
    }

    setItemId(itemID: number) {
        this.setState({itemID})
    }

    setTaskId(taskID: number) {
        this.setState({taskID})
    }

    setTaskIdItemIdFromTitle(taskTitle: string) {
        console.log("changed task id")
        this.props.tasks.forEach((task) => {
            if (task.title === taskTitle) {
                this.setTaskId(task.id)
                this.setItemId(task.itemId)
            }
        })
    }

    setDuration(duration: number) {
        if (isNaN(duration)) duration = 0;
        this.setState({duration})
    }

    setTitle(title: string) {
        this.setState({title})
    }

    submitForm = () => {

        if (this.props.listTaskTitles.includes(this.state.title)) {
            this.setError('Task with Title: "' + this.state.title + '" exists already.')
            return
        }
        if (this.state.title === "" && this.state.duration <= 0) {
            this.setError("Nothing was changed");
            return;
        }
        if (this.state.title === "") {
            // only edit duration
            this.props.onEditTaskDuration(this.state.itemID, this.state.taskID, this.state.duration)
            return;
        }
        if (this.state.duration <= 0) {
            // only edit title
            this.props.onEditTaskTitle(this.state.itemID, this.state.taskID, this.state.title);
            return;
        }

        this.props.onEditTaskDuration(this.state.itemID, this.state.taskID, this.state.duration)
        this.props.onEditTaskTitle(this.state.itemID, this.state.taskID, this.state.title);
        this.setError(null);
    };
}

const mapState = (state: RootState) => {
    return {
        listTaskTitles: getListTaskTitles(state.data),
        listItemTitles: getListItemTitles(state.data),
        items: getItems(state.data),
        tasks: getTasks(state.data)
    }
}

const mapDispatch = (dispatch: Dispatch) => {


    return {
        onEditTaskDuration: (itemID: number, taskID: number, duration: number) => dispatch(editTaskDuration(itemID, taskID, duration)),
        onEditTaskTitle: (itemID: number, taskID: number, title: string,) => dispatch(editTaskTitle(itemID, taskID, title)),
    }
}

const connector = connect(
    mapState,
    mapDispatch
)

interface EditTaskProps {
    className?: string
}

interface EditTaskState {
    taskID: number,
    title: string,
    duration: number,
    error: string | null
    itemID: number
}

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditTask)