import React from "react";
import {Route, Redirect, } from 'react-router-dom'
import {RouteProps, } from 'react-router';
import {connect} from "react-redux";
import {RootState} from "../redux/store";
import {getLoggedIn} from "../redux/authentication/auth.reducer";



interface PrivateRouteProps extends RouteProps {
    loggedIn: boolean,
    opposite?: boolean
}

interface PrivateRouteState {
}

class PrivateRoute extends React.Component<PrivateRouteProps, PrivateRouteState> {
    render() {
        let doOpposite = false;
        if (this.props.opposite !== undefined) doOpposite = this.props.opposite;
        if (!doOpposite) {
            if (this.props.loggedIn) return <Route {...this.props} />;
            else {
                console.log("redirecting someone to /login")
                return <Redirect to="/login"/>;
            }
        } else {
            if (!this.props.loggedIn) return <Route {...this.props} />;
            else return <Redirect to="/"/>;
        }
    }
}



const mapStateToProps = (state: RootState) => ({
    loggedIn: getLoggedIn(state.auth)
});

const connector = connect(mapStateToProps)(PrivateRoute)

export default connector



