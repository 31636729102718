import {applyMiddleware, createStore} from 'redux';
import thunk from "redux-thunk";

import {combineReducers} from 'redux';
import authReducer from './authentication/auth.reducer';
import dataReducer from './data/data.reducer';

import type {INITIAL_STATE_TYPE as authState} from "./authentication/auth.reducer";
import type {INITIAL_STATE_TYPE as dataState} from "./data/data.reducer";


const rootReducer = combineReducers({

    auth: authReducer,
    data: dataReducer,

});

export interface RootState {
    auth: authState,
    data: dataState
}



export default createStore(rootReducer,applyMiddleware(thunk));