import {Card, CardHeader, CardBody, Button, Form, FormGroup, Label, Input, Alert} from "reactstrap";
import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {RootState} from "../../redux/store";
import React from "react";
import {
    getListItemTitles,
} from "../../redux/data/data.reducer";
import {addItem} from "../../redux/data/data.actions";


const mapState = (state: RootState) => {
    return {
        listItemTitles: getListItemTitles(state.data)
    }
}

const mapDispatch = (dispatch: Dispatch) => {
    return {
        onAddItem: (title: string) => dispatch(addItem(title)),
    }
}

const connector = connect(
    mapState,
    mapDispatch
)

interface AddBacklogItemProps {

}

interface AddBacklogItemState {
    title: string,
    error: string | null
}

type PropsFromRedux = ConnectedProps<typeof connector>

class AddBacklogItem extends React.Component<PropsFromRedux & AddBacklogItemProps, AddBacklogItemState> {

    constructor(props: PropsFromRedux) {
        super(props);
        this.state = {
            title: '',
            error: null
        }
    }

    setError(newError: string | null) {
        this.setState({error: newError})
    }

    setTitle(title: string) {
        this.setState({title})
    }

    submitForm = () => {

        if (this.state.title === "") {
            this.setError("Title is required");
            return;
        }

        if (this.props.listItemTitles.includes(this.state.title)) {
            this.setError('Item with Title: "' + this.state.title + '" exists already.')
            return
        }
        this.props.onAddItem(this.state.title);
        this.setError(null);
    };

    render() {
        return (
            <>
                <Card>
                    <CardHeader>
                        Create a new Item
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="itemTitle">Title</Label>
                                <Input type="text" name="title" id="itemTitle"
                                       placeholder="Item Title"
                                       onChange={(event => this.setTitle(event.target.value))}/>
                            </FormGroup>
                            {this.state.error ? <Alert style={{textAlign: "center"}} className={"my-2"}
                                                       color={"danger"}>{this.state.error}</Alert> : <></>}
                            <Button name="addItem" id="addItem" onClick={() => this.submitForm()}>Add Item</Button>
                        </Form>
                    </CardBody>
                </Card>
            </>
        )
    }
}

export default connector(AddBacklogItem)