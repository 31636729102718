import {Container, Row, Col} from "reactstrap";

import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {RootState} from "../../redux/store";
import React from "react";
import {getActiveTasks, getFinishedTasks, getOpenTasks, getTasks} from "../../redux/data/data.reducer";
import {Status} from "../../dataTypes";
import StatusColumn from "./StatusColumn";




class Taskboard extends React.Component<PropsFromRedux, LoginState> {

    render() {
        console.log(this.props)
        return (
            <>
                <Container fluid={true} style={{textAlign: "center", minWidth:'95rem'}} className={'p-5'}>
                    <h1>Taskboard</h1>
                    <Row className={'justify-content-center'}>
                        <Col xs={4}>
                            <StatusColumn status={Status.open}/>
                        </Col>
                        <Col xs={4}>
                            <StatusColumn status={Status.active}/>
                        </Col>
                        <Col xs={4}>
                            <StatusColumn status={Status.finished}/>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
const mapState = (state: RootState) => {
    return {
        openTasks: getOpenTasks(state.data),
        activeTasks: getActiveTasks(state.data),
        finishedTasks: getFinishedTasks(state.data),
        tasks: getTasks(state.data),
    }
}

const mapDispatch = (dispatch: Dispatch) => {

    return {}
}

const connector = connect(
    mapState,
    mapDispatch
)

type PropsFromRedux = ConnectedProps<typeof connector>
type LoginState = {}

export default connector(Taskboard)