import React from "react";

interface clockProps {
    start: number
}

interface clockState {
    time: number
}

export class Clock extends React.Component<clockProps, clockState> {

    private intervalID: NodeJS.Timeout | undefined;

    constructor(props: clockProps) {
        super(props);
        this.state = {
            time: Date.now()
        }
    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        if (this.intervalID) clearInterval(this.intervalID);
    }

    tick() {
        this.setState({
            time: Date.now()
        });
    }

    render() {
        return (
            <>
                Active for {msToTime(this.state.time - this.props.start)}
            </>
        );
    }
}

function msToTime(time: number) {
    const ms = time % 1000;
    time = (time - ms) / 1000;
    const secs = time % 60;
    time = (time - secs) / 60;
    const mins = time % 60;
    const hrs = (time - mins) / 60;

    return hrs > 0 ? zeroPadded(hrs) + ':' + zeroPadded(mins) + ':' + zeroPadded(secs) : zeroPadded(mins) + ':' + zeroPadded(secs);
}

function zeroPadded(number: number) {
    return number < 10 ? 0 + number.toString() : number.toString()
}