import {
    ACTIVATE_TASK,
    ADD_ITEM,
    ADD_TASK, DEACTIVATE_TASK,
    EDIT_ITEM_NAME,
    EDIT_TASK_DURATION,
    EDIT_TASK_TITLE,
    REMOVE_ITEM,
    REMOVE_TASK,
    SET_STATUS
} from './data.types';
import {getExampleUsers, getExampleItems, Item, Status, Task, User, Entry} from "../../dataTypes";

export type INITIAL_STATE_TYPE = {
    items: Item[]
    users: User[]
}

function getTasksFromItems(exampleItems: Item[]) {
    const taskList: Task[] = []
    for (let i = 0; i < exampleItems.length; i++) {
        taskList.push(...exampleItems[i].tasks)
    }
    return taskList
}

const INITIAL_STATE: INITIAL_STATE_TYPE = {
    items: getExampleItems(),
    users: getExampleUsers()
};

const reducer = (state = INITIAL_STATE, action: { type: string, userId?: number, status?: Status, itemId?: number, taskId?: number, title?: string, duration?: number }) => {
    console.log("action", action)
    switch (action.type) {

        case ADD_ITEM:
            return {
                ...state,
                items: addItemToItems(state, action.title)
            }
        case REMOVE_ITEM:
            return {
                ...state,
                items: removeItemFromItems(state, action.itemId)
            }
        case EDIT_ITEM_NAME:
            return {
                ...state,
                items: editItemName(state, action.itemId, action.title)
            }

        case ADD_TASK:
            console.log(action)
            return {
                ...state,
                items: addTaskToItem(state, action.itemId, action.title, action.duration)
            }
        case REMOVE_TASK:
            return {
                ...state,
                items: removeTaskFromItem(state, action.itemId, action.taskId)
            };
        case EDIT_TASK_TITLE:
            return {
                ...state,
                items: editTaskTitle(state, action.taskId, action.title)
            }
        case EDIT_TASK_DURATION:
            return {
                ...state,
                items: editTaskDuration(state, action.taskId, action.duration)
            }

        case SET_STATUS:
            return {
                ...state,
                items: changeTaskStatus(state, action.itemId, action.taskId, action.status),
            };
        case ACTIVATE_TASK:
            return {
                ...state,
                items: activateTask(state, action.taskId, action.userId)
            }
        case DEACTIVATE_TASK:
            return{
                ...state,
                items: deactivateTask(state,action.taskId)
            }

        default:
            return state;
    }
};

function activateTask(state: INITIAL_STATE_TYPE, taskId?: number, userId?: number) {
    if (taskId === undefined || userId === undefined) {
        return state.items
    }
    const itemsCopy = state.items.slice();
    const entry:Entry = {
        id:generateEntryID(state),
        start:Date.now(),
        end:undefined,
        userID:userId,
    }

    for (let item of itemsCopy) {
        for (let task of item.tasks) {
            if (task.id === taskId) {
                task.entries.push(entry)
            }
        }
    }
    return itemsCopy
}


function deactivateTask(state:INITIAL_STATE_TYPE,taskId?:number ){
    if(taskId === undefined){
        return state.items;
    }

    const itemsCopy = state.items.slice();

    for (let item of itemsCopy) {
        for (let task of item.tasks) {
            if (task.id === taskId) {
                for(let entry of task.entries){
                    if(entry.end === undefined){
                        entry.end = Date.now()
                        return itemsCopy
                    }
                }
            }
        }
    }
    return itemsCopy
}

function generateEntryID(state:INITIAL_STATE_TYPE){
    const entries = getEntriesFromTasks(getTasksFromItems(state.items))
    let x = Math.floor(Math.random() * Math.floor(2 ** 20));
    for (let i = 0; i < entries.length; i++) {
        if (entries[i].id === x) {
            // cant use this id, get new random and restart loop
            x = Math.floor(Math.random() * Math.floor(2 ** 20));
            i = 0;
        }
    }
    return x;
}

function getEntriesFromTasks(tasks:Task[]){
    const entryList: Entry[] = []
    for (let i = 0; i < tasks.length; i++) {
        entryList.push(...tasks[i].entries)
    }
    return entryList
}


function editTaskTitle(state: INITIAL_STATE_TYPE, taskId?: number, title?: string) {
    if (taskId === undefined || title === undefined)
        return state.items;

    const itemsCopy = state.items.slice();

    for (let item of itemsCopy) {
        for (let task of item.tasks) {
            if (task.id === taskId) {
                task.title = title;
            }
        }
    }
    return itemsCopy
}

function editTaskDuration(state: INITIAL_STATE_TYPE, taskId?: number, duration?: number) {
    if (taskId === undefined || duration === undefined)
        return state.items;
    const itemsCopy = state.items.slice();

    for (let item of itemsCopy) {
        for (let task of item.tasks) {
            if (task.id === taskId) {
                task.estimatedDuration = duration;
            }
        }
    }
    return itemsCopy
}

function editItemName(state: INITIAL_STATE_TYPE, itemId?: number, title?: string) {
    if (itemId === undefined || title === undefined)
        return state.items;

    const itemsCopy = state.items.slice();

    for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === itemId) {
            itemsCopy[i].title = title;
        }
    }
    return itemsCopy
}

// function editItemDuration(state:INITIAL_STATE_TYPE, itemId?:number,duration?:number){
//     if (itemId === undefined || duration === undefined)
//         return state.items;
//
//     const itemsCopy = state.items.slice();
//
//     for (let i = 0; i < state.items.length; i++) {
//         if (state.items[i].id === itemId) {
//             itemsCopy[i]. = duration;
//         }
//     }
//     return itemsCopy
// }


function addTaskToItem(state: INITIAL_STATE_TYPE, itemId?: number, title?: string, duration?: number): Item[] {
    if (itemId === undefined || title === undefined || duration === undefined)
        return state.items;

    const taskId = generateTaskID(state)
    if (taskId === undefined) return state.items;

    const task: Task = {
        entries: [],
        estimatedDuration: duration,
        itemId: itemId,
        title: title,
        id: taskId,
        status: Status.open
    }


    const itemsCopy = state.items.slice();

    for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === itemId) {
            itemsCopy[i].tasks.push(task)
        }
    }
    return itemsCopy
}

function removeItemFromItems(state: INITIAL_STATE_TYPE, itemID?: number) {
    if (itemID === undefined) {
        return state.items
    }
    const items = state.items.slice();
    return items.filter(item => item.id !== itemID);
}

// function getItemById(state: INITIAL_STATE_TYPE, itemId: number) {
//     const items = state.items;
//     for (let i = 0; i < items.length; i++) {
//         if (items[i].id === itemId) {
//             return items[i]
//         }
//     }
// }

function generateTaskID(state: INITIAL_STATE_TYPE) {

    const tasks = getTasksFromItems(state.items)
    let x = Math.floor(Math.random() * Math.floor(2 ** 20));
    for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].id === x) {
            // cant use this id, get new random and restart loop
            x = Math.floor(Math.random() * Math.floor(2 ** 20));
            i = 0;
        }
    }
    return x;
}

function addItemToItems(state: INITIAL_STATE_TYPE, title: string | undefined) {
    if (title === undefined)
        return state.items;

    const item: Item = {
        id: generateItemID(state),
        title: title,
        tasks: []
    }
    const items = state.items.slice();
    items.push(item);
    return items;
}

function generateItemID(state: INITIAL_STATE_TYPE) {

    const items = state.items

    let x = Math.floor(Math.random() * Math.floor(2 ** 20));
    for (let i = 0; i < items.length; i++) {
        if (items[i].id === x) {
            // cant use this id, get new random and restart loop
            x = Math.floor(Math.random() * Math.floor(2 ** 20));
            i = 0;
        }
    }
    return x;
}

function removeTaskFromItem(state: INITIAL_STATE_TYPE, itemId: number | undefined, taskId: number | undefined) {

    if (taskId === undefined || itemId === undefined)
        return state.items;

    const ItemsCopy = state.items.slice();


    for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === itemId) {
            ItemsCopy[i].tasks = ItemsCopy[i].tasks.filter(task => task.id !== taskId)
        }
    }

    return ItemsCopy
}


function changeTaskStatus(state: INITIAL_STATE_TYPE, itemId: number | undefined, taskId: number | undefined, status: Status | undefined) {


    if (taskId === undefined || itemId === undefined || status === undefined)
        return state.items;

    const ItemsCopy = state.items.slice();

    for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === itemId) {
            for (let j = 0; j < state.items[i].tasks.length; j++) {
                if (state.items[i].tasks[j].id === taskId) {
                    ItemsCopy[i].tasks[j].status = status;
                }
            }
        }
    }

    return ItemsCopy
}


export const getUsers = (state: INITIAL_STATE_TYPE) => state.users
export const getListTaskTitles = (state: INITIAL_STATE_TYPE) => state.items.map((item) => item.tasks).flat().map((task) => task.title)
export const getItems = (state: INITIAL_STATE_TYPE) => state.items
export const getListItemTitles = (state: INITIAL_STATE_TYPE) => state.items.map((item) => item.title)
export const getTasks = (state: INITIAL_STATE_TYPE) => getTasksFromItems(state.items)
export const getOpenTasks = (state: INITIAL_STATE_TYPE) => getTasksByStatus(state, Status.open)
export const getActiveTasks = (state: INITIAL_STATE_TYPE) => getTasksByStatus(state, Status.active)
export const getFinishedTasks = (state: INITIAL_STATE_TYPE) => getTasksByStatus(state, Status.finished)


function getTasksByStatus(state: INITIAL_STATE_TYPE, status: Status) {
    const tasks = getTasksFromItems(state.items)
    const result: Task[] = []
    for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].status === status) result.push(tasks[i])
    }
    return result
}

export default reducer;