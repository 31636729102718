import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import PrivateRoute from './routing/PrivateRoute'
import NotFound from "./NotFound";
import Auth from "./auth/Auth";
import {TaskboardApp} from "./app/TaskboardApp";

function App() {
    return (
        <>
            <Router>
                <Switch>
                    <PrivateRoute exact={true} path={"/login"} component={Auth} opposite={true}/>
                    <PrivateRoute exact={true} path={"/"} component={TaskboardApp}/>
                    <PrivateRoute exact={true} path={"/taskboard"} component={TaskboardApp}/>
                    <PrivateRoute exact={true} path={"/backlog"} component={TaskboardApp}/>
                    <PrivateRoute exact={true} path={"/logout"} component={TaskboardApp}/>
                    <Route component={NotFound}/>
                </Switch>
            </Router>
        </>
    );
}

export default App;
