import {NavLink, Route, Switch} from "react-router-dom";
import NotFound from "../NotFound";
import {Navbar, NavbarBrand} from "reactstrap";
import React from "react";
import Backlog from "./backlog/Backlog";
import Taskboard from "./taskboard/Taskboard";
import Logout from "../auth/Logout";



export const TaskboardApp: React.FC = () => {
    return (
        <>
            <Navbar expand={true} light={true} className="static-top">
                <NavbarBrand href={"/"} className={"mr-auto"}>Scrum Taskboard</NavbarBrand>
                <ul className="navbar-nav ml-auto">
                    <NavLink className={"nav-link"} to={"/backlog"}>Backlog</NavLink>
                    <NavLink className={"nav-link"} to={"/"} exact={true}>Taskboard</NavLink>
                    <NavLink className={"nav-link"} to={"/logout"}>Log out</NavLink>
                </ul>
            </Navbar>
            <Switch>
                <Route exact={true} path={'/backlog'} component={Backlog}/>
                <Route exact={true} path={'/taskboard'} component={Taskboard}/>
                <Route exact={true} path={'/'} component={Taskboard}/>
                <Route exact={true} path={'/logout'} component={Logout}/>
                <Route component={NotFound}/>
            </Switch>
        </>
    )
}
