import {
    LOGIN,
    LOGOUT,
} from './auth.types';
import {getExampleUsers, User} from "../../dataTypes";

export type INITIAL_STATE_TYPE = {
    loggedIn: boolean,
    loginError: String | undefined,
    userID: number | undefined

}

const INITIAL_STATE: INITIAL_STATE_TYPE = {
    loggedIn: !!localStorage.getItem("session"),
    loginError: undefined,
    userID: undefined
};

const reducer = (state = INITIAL_STATE, action: { type: string, error?: String, userID?: number, credentials?: { user: string, password: string } }) => {

    switch (action.type) {

        case LOGIN:
            const verification = verifyUser(action.credentials)
            if (verification.successful) {
                localStorage.setItem("session", "")
                return {
                    ...state,
                    loggedIn: true,
                    loginError: undefined,
                    userID: verification.userID,
                };
            } else {
                localStorage.removeItem("session")
                return {
                    ...state,
                    loggedIn: false,
                    loginError: "Wrong username or password",
                    userID: undefined,
                };
            }

        case LOGOUT:
            localStorage.removeItem("session")
            return {
                ...state,
                loggedIn: false
            }

        default:
            return state;
    }
};

function verifyUser(credentials?: { user: string, password: string }) {
    if (credentials === undefined)
        return {successful: false, userID: undefined};
    const user = getUserByUsername(credentials.user)
    if (user) {
        if (credentials.password === user.password) {
            return {successful: true, userID: user.id}
        }
    }
    return {successful: false, userID: undefined}
}

function getUserByUsername(username: string): User | undefined {
    const list = getExampleUsers()
    for (let i = 0; i < list.length; i++) {
        if (list[i].username === username) {
            return list[i]
        }
    }
}

function getUserByID(id: number | undefined): User | undefined {
    const list = getExampleUsers()
    for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
            return list[i]
        }
    }
}

export const getCurrentUser = (state: INITIAL_STATE_TYPE) => getUserByID(state.userID)
export const getLoggedIn = (state: typeof INITIAL_STATE) => state.loggedIn
export const getLoginError = (state: typeof INITIAL_STATE) => state.loginError

export default reducer;