import {Card, CardHeader, CardBody, Button} from "reactstrap";
import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {RootState} from "../../redux/store";
import React from "react";
import {getActiveTasks, getFinishedTasks, getOpenTasks, getTasks} from "../../redux/data/data.reducer";
import {Item} from "../../dataTypes";
import {login} from "../../redux/authentication/auth.actions";
import {removeItem, removeTask} from "../../redux/data/data.actions";
import './BacklogItem.css'


const mapState = (state: RootState) => {
    return {
        openTasks: getOpenTasks(state.data),
        activeTasks: getActiveTasks(state.data),
        finishedTasks: getFinishedTasks(state.data),
        tasks: getTasks(state.data),
    }
}

const mapDispatch = (dispatch: Dispatch) => {


    return {
        onRemoveItem: (itemId: number) => dispatch(removeItem(itemId)),
        onRemoveTask: (itemId: number, taskId: number) => dispatch(removeTask(itemId, taskId)),
        // @ts-ignore
        onLogin: (credentials: { user: string, password: string }) => dispatch(login(credentials)),
    }
}

const connector = connect(
    mapState,
    mapDispatch
)

interface BacklogItemProps {
    item: Item
}

type PropsFromRedux = ConnectedProps<typeof connector>

class BacklogItem extends React.Component<PropsFromRedux & BacklogItemProps, {}> {

    render() {
        console.log(this.props)
        return (
            <>
                <Card>
                    <CardHeader id={'headerContainer'}>
                        <div className={'pt-2'}>{this.props.item.title}</div>
                        <div>
                            <Button onClick={() => this.props.onRemoveItem(this.props.item.id)}
                                    className={"float-right"} color={"danger"}>
                                <i className="fas fa-minus"/>
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div id={'taskContainer'}>
                            {this.props.item.tasks.map((task) => {
                                return (
                                    <div key={task.id}>
                                        <div style={{flexGrow:3}} className={'pt-2'}>{task.title}</div>
                                        <div className={'pt-2 pr-2'}>{task.status.toString()}</div>
                                        <div><Button color={"danger"}
                                                     className={"float-right"}
                                                     onClick={() => this.removeTask(task.id)}><i
                                            className="fas fa-minus"/></Button></div>
                                    </div>
                                )
                            })}
                        </div>
                    </CardBody>
                </Card>
            </>
        )
    }

    private removeTask(taskId: number) {
        this.props.onRemoveTask(this.props.item.id, taskId);
    }
}

export default connector(BacklogItem)