import {Card, CardBody, CardHeader} from "reactstrap";

import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {RootState} from "../../redux/store";
import React from "react";
import {getActiveTasks, getFinishedTasks, getOpenTasks, getTasks, getUsers} from "../../redux/data/data.reducer";
import TaskContainer from "./TaskContainer";
import {Status} from "../../dataTypes";
import {useDrop} from "react-dnd";
import {setStatus} from "../../redux/data/data.actions";




const StatusColumn: React.FC<PropsFromRedux & StatusColumnProps> = (props) => {

    const [{canDrop, isOver, draggingItem}, drop] = useDrop({
        accept: "task",
        drop: () => ({status: props.status}),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            draggingItem: monitor.getItem()
        }),
    })
    let hasSameStatus = false;
    if (draggingItem !== null) {
        hasSameStatus = draggingItem.status === props.status
    }
    const isActive = canDrop && isOver && !hasSameStatus
    const tasks = props.status === Status.open ? props.openTasks : props.status === Status.active ? props.activeTasks : props.finishedTasks
    const onDropStyle: React.CSSProperties = {
        borderStyle: "dashed",
        margin: "5px 5px 5px 5px"
    }
    const style: React.CSSProperties = {
        padding: "1rem 1rem 1rem 1rem",
        ...(isActive ? onDropStyle : {})
    }
    return (
        <>
            <div ref={drop}>
                <Card style={{minHeight: '20rem', minWidth: '10rem'}}>
                    <CardHeader>
                        {getStatusAsString(props.status)}
                    </CardHeader>
                    <CardBody style={{...style}}>
                        <div>
                            {isActive ? <div>Release to drop</div> : <></>}
                            {tasks.map((task) => {
                                return <TaskContainer key={task.id} task={task}/>
                            })}
                        </div>
                    </CardBody>
                </Card>
            </div>
        </>
    )
}

function getStatusAsString(status:Status){
    return status === Status.open ? 'Open' : status === Status.active ? 'Active' : 'Finished'
}

const mapState = (state: RootState) => {
    return {
        openTasks: getOpenTasks(state.data),
        activeTasks: getActiveTasks(state.data),
        finishedTasks: getFinishedTasks(state.data),
        tasks: getTasks(state.data),
        users: getUsers(state.data)
    }
}

const mapDispatch = (dispatch: Dispatch) => {
    return {
        onSetStatus: (itemId: number, taskId: number, status: Status) => dispatch(setStatus(itemId, taskId, status))
    }
}

const connector = connect(
    mapState,
    mapDispatch
)

type PropsFromRedux = ConnectedProps<typeof connector>

type StatusColumnProps = {
    status: Status
}

export default connector(StatusColumn)