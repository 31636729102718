import React from "react";
import {Entry, User} from "../../dataTypes";
import {Clock} from "./Clock";


export const EntryContainer: React.FC<EntryProps> = (props) => {

    return (
        <>
            <div style={{
                textAlign: "left",
                padding: '0.5em 0 0.5em 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <div>
                    {props.user.firstName + ' ' + props.user.lastName}
                </div>
                <div>
                    {props.entry.end ? (Math.round(( props.entry.end  - props.entry.start)/60 / 60 / 100)/10) : <Clock start={props.entry.start}/>}
                </div>

            </div>

        </>
    )
}

interface EntryProps {
    entry: Entry,
    user: User
}