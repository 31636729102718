import {Card, CardHeader, CardBody, Button, Form, FormGroup, Label, Input, Alert} from "reactstrap";

import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {RootState} from "../../redux/store";
import React from "react";
import {
    getItems,
    getListItemTitles,
} from "../../redux/data/data.reducer";
import {editItemName} from "../../redux/data/data.actions";
import {Item} from "../../dataTypes";

class EditItem extends React.Component<PropsFromRedux & EditItemProps, EditItemState> {

    constructor(props: PropsFromRedux & EditItemProps) {
        super(props);

        this.state = {
            title: '',
            error: null,
            itemID: this.props.items[0] ? this.props.items[0].id : -1
        }
    }

    render() {
        return this.props.items.length < 1 ? <></> : (
            <>
                <Card className={this.props.className}>
                    <CardHeader>
                        Edit Item
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="itemSelect">Select an Item</Label>
                                <Input type="select" name="select" id="itemSelect"
                                       onChange={event => this.setItemIdFromTitle(event.target.value)}>
                                    {this.props.items.map((item: Item) => {
                                        return (
                                            <option key={item.id}>
                                                {item.title}
                                            </option>
                                        )
                                    })}

                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="taskTitle">Title</Label>
                                <Input type="text" name="title" id="taskTitle"
                                       placeholder="New Title"
                                       onChange={(event => this.setTitle(event.target.value))}/>
                            </FormGroup>
                            {this.state.error ? <Alert style={{textAlign: "center"}} className={"my-2"}
                                                       color={"danger"}>{this.state.error}</Alert> : <></>}
                            <Button name="addItem" id="addItem" onClick={() => this.submitForm()}>Save Edit</Button>
                        </Form>
                    </CardBody>
                </Card>
            </>
        )
    }


    setError(newError: string | null) {
        this.setState({error: newError})
    }

    setItemId(itemID: number) {
        this.setState({itemID})
    }

    setItemIdFromTitle(itemTitle: string) {
        console.log("changed item id")
        this.props.items.forEach((item) => {
            if (item.title === itemTitle) this.setItemId(item.id)
        })
    }

    setTitle(title: string) {
        this.setState({title})
    }

    submitForm = () => {
        if (!this.props.items.map(item => item.id).includes(this.state.itemID)) {
            this.setError("Item with id " + this.state.itemID + ' doesn\'t exist');
            return;
        }
        if (this.props.listItemTitles.includes(this.state.title)) {
            this.setError('Item with Title: "' + this.state.title + '" exists already.')
            return
        }
        if (this.state.title === "" ) {
            // do nothing
            this.setError("Nothing was changed");
            return;
        }

        this.props.onEditItemName(this.state.itemID, this.state.title);
        // this.props.onEditItemDuration(this.state.itemID, this.state.duration);
        this.setError(null);
    };


}

const mapState = (state: RootState) => {
    return {
        listItemTitles: getListItemTitles(state.data),
        items: getItems(state.data)
    }
}

const mapDispatch = (dispatch: Dispatch) => {


    return {
        onEditItemName: (itemID: number, title: string) => dispatch(editItemName(itemID, title)),
    }
}

const connector = connect(
    mapState,
    mapDispatch
)

interface EditItemProps {
    className?: string
}

interface EditItemState {
    title: string,
    error: string | null
    itemID: number
}

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditItem)