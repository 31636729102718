import {Form, FormGroup, Label, Input, Button, Card, CardBody, Container, Row, Col, Alert} from "reactstrap";
import {login} from "../redux/authentication/auth.actions";
import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {RootState} from "../redux/store";
import React from "react";
import {getLoggedIn, getLoginError} from "../redux/authentication/auth.reducer";


class Login extends React.Component<PropsFromRedux, LoginState> {

    constructor(props: PropsFromRedux) {
        super(props);
        this.state = {
            user: '',
            password: '',
            error: null
        }

    }

    render() {
        console.log("login render", this.props)
        return (
            <>
                <Container className="pt-lg-5 pt-md-5">
                    <Row className="justify-content-center text-center mb-7">
                        <Col xl={5} lg={6} md={8} className="px-5">
                            <h1>Welcome!</h1>
                            <p className="text-lead">Login and start working</p>
                        </Col>
                    </Row>
                </Container>
                <Container className={'mt-5 px-sm-5 px-3'}>
                    <Row className={'justify-content-center'}>
                        <Col lg={5} md={7}>
                            <Card>
                                <CardBody>
                                    <Form>
                                        <h2>Login</h2>
                                        <FormGroup>
                                            <Label for="username">User</Label>
                                            <Input type="text" name="user" id="username"
                                                   placeholder="with a placeholder"
                                                   onChange={e => this.setUsername(e.target.value)}/>
                                        </FormGroup>
                                        <FormGroup className={"pb-1"}>
                                            <Label className={"pb-1"} for="examplePassword">Password</Label>
                                            <Input type="password" name="password" id="examplePassword"
                                                   placeholder="password placeholder"
                                                   onChange={e => this.setPassword(e.target.value)}/>
                                        </FormGroup>
                                        {this.props.loginError ? <Alert style={{textAlign: "center"}} className={"my-2"}
                                                                        color={"danger"}>{this.props.loginError}</Alert> : <></>}
                                        <Button style={{margin: "0 auto", display: "block"}} className={"mt-3"}
                                                onClick={this.submitForm}>Login</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    setError(newError: string) {
        this.setState({error: newError})
    }

    setUsername(user: string) {
        this.setState({user})
    }

    setPassword(newPassword: string) {
        this.setState({password: newPassword})
    }

    submitForm = () => {
        if (this.state.user === "" || this.state.password === "") {
            this.setError("Fields are required");
            return;
        }
        this.props.onLogin({user: this.state.user, password: this.state.password});
    };
}

const mapState = (state: RootState) => {
    return {
        loggedIn: getLoggedIn(state.auth),
        loginError: getLoginError(state.auth)
    }
}

const mapDispatch = (dispatch: Dispatch) => {

    return {
        onLogin: (credentials: { user: string, password: string }) => dispatch(login(credentials)),
    }
}

const connector = connect(
    mapState,
    mapDispatch
)

type PropsFromRedux = ConnectedProps<typeof connector>
type LoginState = {
    user: string,
    password: string,
    error: string | null
}

export default connector(Login)