import {
    ACTIVATE_TASK,
    ADD_ITEM, ADD_TASK, DEACTIVATE_TASK, EDIT_ITEM_NAME, EDIT_TASK_DURATION, EDIT_TASK_TITLE, REMOVE_ITEM,
    REMOVE_TASK,
    SET_STATUS
} from './data.types';
import {Status} from '../../dataTypes'

export function setStatus(itemId: number, taskId: number, status: Status) {
    return {
        type: SET_STATUS,
        status,
        itemId,
        taskId
    }
}

export function addTask(itemId: number, title: string, duration: number) {
    return {
        type: ADD_TASK,
        itemId,
        title,
        duration
    }
}

export function removeTask(itemId: number, taskId: number) {
    return {
        type: REMOVE_TASK,
        itemId,
        taskId,
    }
}

export function addItem(title: string) {
    return {
        type: ADD_ITEM,
        title,
    }
}

export function activateTask(taskId: number, userId:number){
    return{
        type: ACTIVATE_TASK,
        taskId,
        userId
    }
}

export function deactivateTask(taskId: number){
    return {
        type: DEACTIVATE_TASK,
        taskId
    }
}

export function editItemName(itemId: number, title: string) {
    return {
        type: EDIT_ITEM_NAME,
        itemId,
        title,

    }
}

export function editTaskDuration(itemId: number, taskId: number, duration: number) {
    return {
        type: EDIT_TASK_DURATION,
        itemId,
        taskId,
        duration
    }
}

export function editTaskTitle(itemId: number, taskId: number, title: string) {
    return {
        type: EDIT_TASK_TITLE,
        itemId,
        taskId,
        title
    }
}

export function removeItem(id: number) {
    return {
        type: REMOVE_ITEM,
        itemId: id,
    }
}

