import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {RootState} from "../../redux/store";
import {getItems} from "../../redux/data/data.reducer";
import BacklogItem from "./BacklogItem";
import AddBacklogItem from "./AddBacklogItem";
import AddTask from "./AddTask";
import './Backlog.css';
import EditItem from "./EditItem";
import EditTask from "./EditTask";


class Backlog extends React.Component<PropsFromRedux, {}> {



    render() {
        if(this.props.items.length === 0) return(
            <div className={'p-5'}>
                <h1>Backlog</h1>
                <h2>Create</h2>
                <div id={'backlogContainer'}>
                    <div style={{maxWidth: '30rem'}}>
                        <AddBacklogItem/>
                    </div>
                </div>
            </div>
        )
        return (
            <>
                <div className={'p-5'}>
                    <h1>Backlog</h1>
                    <div id={'backlogContainer'}>
                        <div id={'options'}>
                            <div id={'addElement'}>
                                <h2>Create</h2>
                                <AddBacklogItem/>
                                <AddTask className={"mt-3"}/>
                            </div>
                            <div className={'pl-3'} id={'editElement'}>
                                <h2>Edit</h2>
                                <EditItem/>
                                <EditTask className={"mt-3"}/>
                            </div>
                        </div>
                        <div>
                            <h2>Items</h2>
                            <div id={'backlogItems'}>
                                {this.props.items.map((item) => {
                                    return (
                                        <div className={'px-2'} key={item.id}>
                                            <BacklogItem item={item}/>
                                        </div>
                                    )
                                })}</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapState = (state: RootState) => {
    return {
        items: getItems(state.data)
    }
}

const mapDispatch = (dispatch: Dispatch) => {
    return {}
}

const connector = connect(
    mapState,
    mapDispatch
)

type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(Backlog)
