import React from 'react';
import Login from "./Login";
import {connect, ConnectedProps} from "react-redux";
import {Container, Navbar} from "reactstrap";
import {RootState} from "../redux/store";
import './Auth.css'
import {getLoggedIn} from "../redux/authentication/auth.reducer";


class Auth extends React.Component<PropsFromRedux, any> {
    render() {
        return (
            <>
                <div id={"auth-wrapper"}>
                    <Container className={'pt-3'}>
                        <Navbar expand={true} light={true}
                                className="static-top">
                        </Navbar>
                        <Login/>
                    </Container>
                </div>
            </>
        )
    }
}

type PropsFromRedux = ConnectedProps<typeof connector>


const mapState = (state: RootState) => {
    return {
        loggedIn: getLoggedIn(state.auth)
    }
}


const connector = connect(
    mapState
)

export default connector(Auth)